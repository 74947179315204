import { onMount, createSignal, createEffect, Show } from 'solid-js';
import { ZERO_RESULTS_DESC, MAP_ERROR_DESC } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';

interface IProps {
  address: string;
}

declare global {
  interface Window {
    initMap: () => void;
    google: any;
  }
}

const GoogleMap = (props: IProps) => {
  const { t } = useLocalization();
  let mapRef: HTMLDivElement | undefined;
  const [error, setError] = createSignal<string>();
  const [map, setMap] = createSignal();
  const [isScriptLoaded, setIsScriptLoaded] = createSignal(false);

  const loadGoogleMapsScript = () => {
    if (window.google && window.google.maps) {
      setIsScriptLoaded(true);
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB89updWgLCG03Q81M-bZm0ESgVpSyCZ0I&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setIsScriptLoaded(true);
        resolve();
      };
      script.onerror = (error) => {
        console.error('Failed to load Google Maps script:', error);
        reject(error);
      };
      document.head.appendChild(script);
    });
  };

  const initializeMap = () => {
    if (!mapRef || !window.google || !window.google.maps) {
      console.error('Map reference or Google Maps API not available');
      return;
    }

    const newMap = new window.google.maps.Map(mapRef, {
      center: { lat: 0, lng: 0 },
      zoom: 15,
    });
    setMap(newMap);
  };

  const retryOperation = async (operation: () => Promise<void>, maxRetries = 2, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        await operation();
        return;
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === maxRetries - 1) throw error;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  onMount(async () => {
    try {
      await retryOperation(async () => {
        await loadGoogleMapsScript();
        await new Promise((resolve) => setTimeout(resolve, 100));
        initializeMap();
      });
    } catch (error) {
      console.error('Failed to initialize map after retries:', error);
      setError(t(MAP_ERROR_DESC));
    }
  });

  createEffect(() => {
    if (isScriptLoaded() && map() && props.address) {
      const currentMap = map() as any;
      if (!currentMap || !props.address || !window.google || !window.google.maps) {
        console.error('Map, address, or Google Maps API not available', {
          map: currentMap,
          address: props.address,
          googleMaps: !!window.google?.maps,
        });
        return;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: props.address }, (results: { geometry: { location: any } }[], status: any) => {
        const firstResult = results && results[0];
        if (status === window.google.maps.GeocoderStatus.OK && firstResult) {
          const location = firstResult.geometry.location;
          currentMap.setCenter(location);
          new window.google.maps.Marker({ position: location, map: currentMap });
          setError(undefined);
        } else {
          const errorMessage = status === window.google.maps.GeocoderStatus.ZERO_RESULTS ? t(ZERO_RESULTS_DESC) : t(MAP_ERROR_DESC);
          console.error('Geocoding failed:', { status, errorMessage });
          setError(errorMessage);
        }
      });
    }
  });

  return (
    <div class="relative size-full h-modalSm sm:h-[400px]">
      <Show
        when={!error()}
        fallback={
          <div class="flex size-full flex-col items-center justify-center rounded-lg bg-gradient-color-start px-2 text-sm text-text-level03">
            {error()}
          </div>
        }>
        <div ref={mapRef} class="size-full rounded-lg" />
      </Show>
    </div>
  );
};

export default GoogleMap;
